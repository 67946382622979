.hero-section {
  background-image: url('../../assets/HomepageHero.png');
  background-size: cover;
  background-position: center;
  height: 80vh;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.hero-content {
  position: relative;
  z-index: 2;
  color: white;
  top: 25%
}

.hero-section::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1;
}

.homepage h2 {
  color: #335DA8;
  font-size: 32px;

}

.btn-primary,
.btn-primary:hover,
.btn-primary:active,
.btn-primary:visited {
  background-color: #335DA8 !important;
}

.number {
  width: 30px;
  height: 30px;
  border-radius: 15px;
  background-color: #335DA8;
  /*  display: flex;
    justify-content: center;
    align-items: center;
    align-self: center; */
}

.number p {
  color: #ffffff;
  text-align: center;
}

.homepage .sub-text {
  color: #76A1EF;
  margin-top: 0;
  margin-bottom: 0;
}

.custom-card {
  border-radius: 15px; /* Runde Ecken */
  overflow: hidden; /* Damit das Bild die runden Ecken respektiert */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Optionaler Schatten */
}

.custom-img {
  height: 300px; /* Höhe des Bildes */
  object-fit: cover; /* Beschneidet das Bild gleichmäßig, um es passend zu machen */
  width: 100%; /* Volle Breite des Containers */
}

.homepage .card-body {
  text-align: left; /* Text linksbündig ausrichten */
  padding: 1rem; /* Standard-Padding von Bootstrap */
}

.homepage .card-title {
  font-size: 24px; /* Titelgröße anpassen */
  font-weight: bold;
}

.homepage .card-subtitle {
  font-size: 18px; /* Untertitelgröße anpassen */
  color: #6c757d; /* Leicht abgedunkelter Untertitel */
}

.slick-slide > div {
  margin: 0 10px;
}
