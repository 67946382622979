body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  --primary-bg-color: #687E6C;
  --secondary-bg-color: #5E7862;
  --detail-primary-bg-color: #557059;
  --detail-secondary-bg-color: #5E7862;
  --primary-color: #FEF5EC;
  min-height: 100dvh;


}

/* Add a black background color to the top navigation */
.topnav {
  background-color: rgb(255, 255, 255);
  border-bottom: 1px solid #335DA8;
  font-family: "Inter";
  overflow: hidden;
  display: flex;
  /* flex macht alle div's in einer Reihe, also alle div's, die innerhalb diesem Teil sind */
  justify-content: space-between;

}

/* Style the links inside the navigation bar */
.topnav a {
  float: left;
  /* macht, dass alles in einer Reihe ist, von links aus. Ohne das, wäre es untereinander */
  display: block;
  color: #000000;
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
  font-size: 17px;
}

/* Change the color of links on hover */
.topnav a:hover {
  background-color: #ddd;
  color: black;
}

/* Add an active class to highlight the current page */
.topnav a.active {
  background-color: #04AA6D;
  color: white;
}

/* Hide the link that should open and close the topnav on small screens */
.topnav .icon {
  display: none;
}

.pill-navigation {
  overflow: hidden;
  display: flex;
  justify-content: center;
}

.pill-button {
  background-color: #EFEFEF;
  border: none;
  color: #838383;
  padding: 10px 20px;
  font-weight: 600;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 16px;
}

.pill-button.active {
  background-color: #D8E4F7;
  color: #335DA8;
}

.nav-link {
  color: #F1F8E8 !important;
}

.nav-link.active {
  color: #F1F8E8 !important;
}

.container.detail {
  background-color: var(--detail-primary-bg-color);
}

.hero-image {
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url("img/Hero.png");
  height: 100vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}

.hero-text {
  font-family: "Inter";
  text-align: center;

  color: '#000000';
  background-color: rgba(255, 255, 255, 0.85);
  width: 820px;
  height: 282px;
  padding: 5%;
  border-radius: 15px;
}

.inhalt-hero {
  position: absolute;

  background-color: rgba(255, 255, 255, 0.85);
  border-radius: 15px;
  padding: 3rem;
  padding-left: 10rem;
  padding-right: 10rem;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.button {
  background-color: #335DA8;
  color: rgb(255, 255, 255);
  border: none;
  border-radius: 10px;
  width: 410px;
  height: 40px;
  margin-top: 20px;
  font-size: 17px;
}

.custom-imput {
  text-align: left;
  display: grid;
}


.inhalt-hero label {
  margin-bottom: 5px;
  text-align: left;
  float: left;
}


.inhalt-hero input {
  width: 405px;
  height: 30px;
  border-radius: 5px;
  border-color: #3B3B3B;
  border: solid 1px;
  background-color: transparent;
}